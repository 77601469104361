import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';

import Input from '../../../../../../Utils/Requests/CreateRequest/Input/Input';
import ModalSelect from '../../../../../../Utils/Requests/CreateRequest/ModalSelect';
import NomenclatureTypeSelect from '../../../../../../Utils/Requests/CreateRequest/Selectors/NomenclatureTypeSelect';

import InputWithButtons from '../../../../../../Utils/Requests/CreateRequest/Input/InputWithButtons/InputWithButtons';
import PMD from '../../../../../../Utils/Requests/CreateRequest/Input/PMD/PMD';

import { RootState } from '../../../../../../../store/store';
import { useGetNomenclaturesQuery } from '../../../../../../../servises/api';

interface NomenclatureProps {
    nomenclature: string;
    capacity: number;
    coneDraft: number;
    setCapacity: React.Dispatch<React.SetStateAction<number>>;
    setConeDraft: React.Dispatch<React.SetStateAction<number>>;
    request?: any;
    addressUID?: string;
    isEditable: boolean;
    refNomenclatureSelector: React.RefObject<HTMLDivElement>;
}

function Nomenclature({
    nomenclature,
    capacity,
    coneDraft,
    setCapacity,
    setConeDraft,
    request,
    addressUID,
    isEditable,
    refNomenclatureSelector,
}: NomenclatureProps) {
    const [selectedNomenclature, setSelectedNomenclature] = useState(nomenclature);

    const savedNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );

    const { data: dataNomenclatures, isSuccess: isSuccessNomenclatures } = useGetNomenclaturesQuery(
        addressUID,
        {
            skip: !addressUID as unknown as boolean,
        },
    );

    useEffect(() => {
        if (savedNomenclature) {
            setSelectedNomenclature(savedNomenclature);
        }
    }, [savedNomenclature]);

    useEffect(() => {
        if (capacity <= 0) {
            capacity = 0;
        }

        if (capacity === null) {
            capacity = 0;
        } else if (capacity >= 10000) {
            capacity = 10000;
        }
    }, [capacity]);

    useEffect(() => {
        if (coneDraft <= 0) {
            coneDraft = 0;
        }

        if (coneDraft === null) {
            coneDraft = 0;
        } else if (coneDraft >= 10000) {
            coneDraft = 10000;
        }
    }, [coneDraft]);

    return (
        <>
            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Что и сколько</span>

                <div className="nomenclatureAndCapacityContent">
                    <ModalSelect
                        refNomenclatureSelector={refNomenclatureSelector}
                        request={request}
                        isLoaded={isSuccessNomenclatures}
                        selectTitle={'Номенклатура'}
                        route={'updateNomenclature'}
                        selectedInformation={selectedNomenclature}
                        isEditable={isEditable}
                        content={dataNomenclatures}
                    />

                    {selectedNomenclature === 'Цементное молоко' ||
                        selectedNomenclature === 'Цементное молоко густое' ? (
                        <>
                            <InputWithButtons
                                inputTitle={'Объём, м'}
                                inputValue={capacity}
                                setInputValue={setCapacity}
                                valueToChange={0.5}
                            />
                            <NomenclatureTypeSelect />
                        </>
                    ) : (
                        <>
                            <NomenclatureTypeSelect />

                            <Input
                                capacity={capacity}
                                setCapacity={setCapacity}
                                coneDraft={coneDraft}
                                setConeDraft={setConeDraft}
                                request={request}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Дополнительно</span>
                <div className="additionalMenuContent">
                    <PMD request={request} />
                </div>
            </div>
        </>
    );
}

export default Nomenclature;
