import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';

import toast from 'react-hot-toast';

import ModalSelect from '../../../../Utils/Requests/CreateRequest/ModalSelect';
import Input from '../../../../Utils/Requests/CreateRequest/Input/Input';
import NomenclatureTypeSelect from '../../../../Utils/Requests/CreateRequest/Selectors/NomenclatureTypeSelect';
import InputWithButtons from '../../../../Utils/Requests/CreateRequest/Input/InputWithButtons/InputWithButtons';
import PMD from '../../../../Utils/Requests/CreateRequest/Input/PMD/PMD';

import { useGetNomenclaturesQuery } from '../../../../../servises/api';
import { useDispatch } from 'react-redux';
import {
    selectNomenclature,
    selectNomenclatureUID,
    setIsGraniteavailable,
    setNomenclatureMobility,
} from '../../../../../store/slices/sliceSelectedInfo';

interface NomenclatureProps {
    capacity: number;
    setCapacity: Dispatch<SetStateAction<number>>;
    coneDraft: number;
    setConeDraft: Dispatch<SetStateAction<number>>;
    addressUID: string;
    selectedNomenclature: string;
    requestCopy: boolean;
    refNomenclatureSelector: React.RefObject<HTMLDivElement>;
}

function Nomenclature({
    capacity,
    setCapacity,
    coneDraft,
    setConeDraft,
    addressUID,
    selectedNomenclature,
    requestCopy,
    refNomenclatureSelector,
}: NomenclatureProps) {
    const [isEditable, setIsEditable] = useState(false);
    const dispatch = useDispatch();

    const { data: dataNomenclatures, isSuccess: isSuccessNomenclatures } = useGetNomenclaturesQuery(
        addressUID,
        {
            skip: !addressUID as unknown as boolean,
        },
    );

    useEffect(() => {
        if (isSuccessNomenclatures) {
            if (
                dataNomenclatures &&
                Object.keys(dataNomenclatures).length === 1 &&
                !selectedNomenclature
            ) {
                if (Object.keys(dataNomenclatures).length === 1 && !selectedNomenclature) {
                    dispatch(selectNomenclature(dataNomenclatures[0]?.title));
                    dispatch(selectNomenclatureUID(dataNomenclatures[0]?.nomenclature_uid));
                    dispatch(setIsGraniteavailable(dataNomenclatures[0]?.options));
                    dispatch(setNomenclatureMobility(dataNomenclatures[0]?.options[0]?.mobility));
                }
            }
        }
    }, [dataNomenclatures]);

    useEffect(() => {
        if (requestCopy) {
            setIsEditable(false);
        } else if (addressUID && !requestCopy) {
            setIsEditable(true);
        }
    }, [requestCopy, addressUID]);

    useEffect(() => {
        if (capacity <= 0) {
            setCapacity(0);
        } else if (capacity === null) {
            setCapacity(0);
        } else if (capacity >= 9999) {
            setCapacity(9999);
        }

        if (coneDraft === null) {
            setConeDraft(10);
        }
    }, [coneDraft, capacity]);

    return (
        <>
            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Что и сколько</span>
                <div className="nomenclatureAndCapacityContent">
                    <ModalSelect
                        isLoaded={isSuccessNomenclatures}
                        refNomenclatureSelector={refNomenclatureSelector}
                        isEditable={isEditable}
                        request={requestCopy}
                        selectTitle={'Номенклатура'}
                        selectedInformation={selectedNomenclature}
                        route={'addNomenclature'}
                        content={dataNomenclatures}
                        addressUID={addressUID}
                        methodToGetContent={`nomenclature`}
                    />
                    {selectedNomenclature === 'Цементное молоко' ||
                        selectedNomenclature === 'Цементное молоко густое' ? (
                        <>
                            <InputWithButtons
                                inputTitle={'Объём, м3'}
                                inputValue={capacity}
                                setInputValue={setCapacity}
                                valueToChange={0.5}
                                maxValue={99999}
                            />
                            <NomenclatureTypeSelect />
                        </>
                    ) : (
                        <>
                            <NomenclatureTypeSelect />

                            <Input
                                capacity={capacity}
                                setCapacity={setCapacity}
                                coneDraft={coneDraft}
                                setConeDraft={setConeDraft}
                            />
                        </>
                    )}
                </div>
            </div>
            <div className="nomenclatureAndCapacity">
                <span className="titleRequestMenu">Дополнительно</span>
                <div className="additionalMenuContent">
                    <PMD />
                </div>
            </div>
        </>
    );
}

export default Nomenclature;
