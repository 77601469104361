import React, { useEffect } from 'react';

import styles from './PMD.module.css';

import { useDispatch, useSelector } from 'react-redux';
import { setPMD } from '../../../../../../store/slices/sliceSelectedInfo';

import { RootState } from '../../../../../../store/store';

function PMD({ request }: { request?: any }) {
    const PMDValue = useSelector((state: RootState) => state.selectedInfo.PMD);

    const dispactch = useDispatch();

    useEffect(() => {
        if (request) {
            if (request.pmd) {
                dispactch(setPMD(request.pmd));
            }
        }
    }, [request, dispactch]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        dispactch(setPMD(event.target?.value));
    };

    return (
        <div className={styles.placeholder}>
            <div className={styles.position}>
                <div className={styles.txtPlaceholder}>
                    <div className={styles.txts}>
                        <span className={styles.label}>Противоморозная добавка</span>
                        <select
                            value={PMDValue}
                            onChange={(e) => handleChange(e)}
                            className={styles.value}
                        >
                            <option className={styles.option} value="Без ПМД">
                                Без ПМД
                            </option>
                            <option className={styles.option} value="До -5°C">
                                До -5°C
                            </option>
                            <option className={styles.option} value="До -10°C">
                                До -10°C
                            </option>
                            <option className={styles.option} value="До -15°C">
                                До -15°C
                            </option>
                            <option className={styles.option} value="До -20°C">
                                До -20°C
                            </option>
                        </select>
                    </div>
                </div>
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16 24" fill="none">
                    <g clipPath="url(#clip0_1684_5782)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.21967 9.21967C2.51256 8.92678 2.98744 8.92678 3.28033 9.21967L7.75 13.6893L12.2197 9.21967C12.5126 8.92678 12.9874 8.92678 13.2803 9.21967C13.5732 9.51256 13.5732 9.98744 13.2803 10.2803L8.28033 15.2803C7.98744 15.5732 7.51256 15.5732 7.21967 15.2803L2.21967 10.2803C1.92678 9.98744 1.92678 9.51256 2.21967 9.21967Z" fill="#202020" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1684_5782">
                            <rect width="24" height="16" fill="white" transform="translate(16) rotate(90)" />
                        </clipPath>
                    </defs>
                </svg> */}
            </div>
        </div>
    );
}

export default PMD;
