import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import InputWithButtons from '../Input/InputWithButtons/InputWithButtons';

import Header from '../Header';

import styles from './NasosSettings.module.css';
import { RootState } from '../../../../../store/store';
import {
    setIzsLaitanceRequired,
    setLaitance,
    setOwnPumps,
    setPumpCount,
} from '../../../../../store/slices/sliceSelectedInfo';
import BoldPlus from '../../../../../content/svg/RequestsPage/BoldPlus';
import CheckBox from '../../../../Features/CheckBox/CheckBox';
import RightArrow from '../../../../../content/svg/RequestsPage/RightArrow';
import toast from 'react-hot-toast';
import { setNasosDate } from '../../../../../store/slices/sliceRequests';
import { format, parse, subHours } from 'date-fns';

function NasosSettings({ backRedirect }: { backRedirect: string }) {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const ownPumps = useSelector((state: RootState) => state.selectedInfo.ownPumps);
    const laitance = useSelector((state: RootState) => state.selectedInfo.laitance);
    const pumpsInfo = useSelector((state: RootState) => state.selectedInfo.pumps);
    const isLaitanceRequired = useSelector(
        (state: RootState) => state.selectedInfo.isLaitanceRequired,
    );
    const nasosDate = useSelector((state: RootState) => state.requests.nasosDate);

    const [localOwnPumps, setLocalOwnPumps] = useState(ownPumps);
    const [localLaitance, setLocalLaitance] = useState(laitance);
    const [localIsLaitanceRequired, setLocalIsLaitanceRequired] = useState(isLaitanceRequired);

    const saveData = () => {
        dispatch(setPumpCount(localOwnPumps + pumpsInfo.length));
        dispatch(setLaitance(localIsLaitanceRequired ? localLaitance : 0));
        dispatch(setIzsLaitanceRequired(localIsLaitanceRequired));
        dispatch(setOwnPumps(localOwnPumps));
    };

    const saveButtonHandler = () => {
        if (localOwnPumps || pumpsInfo.length) {
            saveData();
            navigate(-1);
        } else {
            toast.error('Укажите насосы');
        }
    };

    useEffect(() => {
        dispatch(setNasosDate(subHours(nasosDate, 2)));
    }, []);

    return (
        <div className={styles.page}>
            <Header headerTitle="Параметры насоса" backRedirect={backRedirect} />
            <div className={styles.content}>
                <div className={styles.block}>
                    <span className="titleRequestMenu">Насосы на площадке</span>
                    <InputWithButtons
                        inputTitle={'Укажите количество насосов, шт'}
                        inputValue={localOwnPumps}
                        setInputValue={setLocalOwnPumps}
                        valueToChange={1}
                        maxValue={3}
                        minValue={0}
                    />
                </div>
                <div className={styles.block}>
                    <span className="titleRequestMenu">Цементное молоко</span>
                    <div className={styles.milkSwitcher}>
                        <span>Нужно цементное молоко</span>
                        <CheckBox
                            onChange={setLocalIsLaitanceRequired}
                            checked={localIsLaitanceRequired}
                            name="isLaitanceRequired"
                        />
                    </div>
                    {localIsLaitanceRequired ? (
                        <InputWithButtons
                            inputTitle={'Цементное молоко, м'}
                            inputValue={localLaitance}
                            setInputValue={setLocalLaitance}
                            valueToChange={0.5}
                            minValue={0.5}
                        />
                    ) : null}
                </div>

                <div className={styles.pumpsBlock}>
                    {pumpsInfo.length ? (
                        <span className="titleRequestMenu">Насосы под заказ</span>
                    ) : null}
                    <div className={styles.pumpsList}>
                        {pumpsInfo.map((value: any, index) => (
                            <div
                                key={index}
                                className={styles.pump}
                                onClick={() =>
                                    navigate('/nasosOrder', {
                                        state: { pumpInfo: value, index: index },
                                    })
                                }
                            >
                                <div className={styles.pumpInfo}>
                                    <span className={styles.pumpHeader}>Параметры насоса</span>
                                    {format(
                                        parse(
                                            value.nasosDate as unknown as string,
                                            'dd.MM.yyyy HH:mm',
                                            new Date(),
                                        ),
                                        'dd.MM HH:mm',
                                    )}
                                    ; {value.arrowLength === 'Линейный насос' ? `${value.arrowLength}` : `${value.arrowLength} м`}; {value.objectHeight}x
                                    {value.objectWidth}; {value.pipeCount} тр.{' '}
                                    {value.isTubeRequired ? `+ шланг` : ''}
                                </div>
                                <RightArrow />
                            </div>
                        ))}
                    </div>
                    {pumpsInfo.length < 3 && (
                        <button
                            className={styles.addNasos}
                            onClick={() => {
                                saveData();
                                navigate('/nasosOrder');
                            }}
                        >
                            <BoldPlus />
                            Насос под заказ
                        </button>
                    )}
                </div>
                <button className={styles.saveButton} onClick={saveButtonHandler}>
                    Сохранить
                </button>
            </div>
        </div>
    );
}

export default NasosSettings;
