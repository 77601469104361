/* eslint no-use-before-define: 0 */ // --> OFF

import React from 'react';
import { useEffect } from 'react';

import './SubmitButton.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { format } from 'date-fns';

import toast from 'react-hot-toast';

import { mobileProrabIntstance } from '../../Entities/Lib/axios';
import { useAddRequestMutation } from '../../../servises/api';
import { getCookie } from '../../Entities/Lib/CookieWork';

import { RootState } from '../../../store/store';
import Trash from '../../../content/svg/RequestsPage/Trash';
import ModalError from '../../Utils/ModalError/ModalError';
import { addMark } from '../../../store/slices/sliceMarks';

interface SubmitButtonProps {
    nomenclatureUID: string;
    methodToExecute: string;
    requestType?: string;
    initialTextValue: string;
    successType: string;
    addressUID: string;
    contactInformation: string;
    selectedAcceptanceType: string;
    intervalValue: string;
    intensity: number;
    capacity: number;
    coneDraft: number;
    date: Date;
    receivingMethod: string;
    deliveryType: string;
    factoryType: string;
    orderUID?: string;
    pumpCount: number;
    laitance: number;
    crane: number;
    refAddressSelector: React.RefObject<HTMLDivElement>;
    refNomenclatureSelector: React.RefObject<HTMLDivElement>;
    refPhoneSelector: React.RefObject<HTMLDivElement>;
    refPumpSelector?: React.RefObject<HTMLDivElement>;
    action: any; //todo
    request?: any; //todo
    isRequestCopy?: boolean;
}

function SubmitButton({
    methodToExecute,
    requestType,
    initialTextValue,
    successType,
    addressUID,
    nomenclatureUID,
    contactInformation,
    selectedAcceptanceType,
    intervalValue,
    intensity,
    capacity,
    coneDraft,
    date,
    receivingMethod,
    deliveryType,
    factoryType,
    orderUID,
    pumpCount,
    laitance,
    refAddressSelector,
    refNomenclatureSelector,
    refPhoneSelector,
    refPumpSelector,
    action,
    request,
    isRequestCopy,
    crane,
}: SubmitButtonProps) {
    const theme = localStorage.getItem('theme');
    const [refreshReq] = useAddRequestMutation();
    const dispatch = useDispatch();

    const [isOpenError, setIsOpenError] = useState(false);

    const [submitButtonText, setSubmitButtonText] = useState(initialTextValue);
    const [isButtonAvaileble, setIsButtonAvaileble] = useState(true);
    const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(false);

    const [isTodaysDate, setIsTodaysDate] = useState(
        typeof date === 'string' ? format(+date, 'dd') === format(new Date(), 'dd') : false,
    );

    const selectedFiller = useSelector((state: RootState) => state.selectedInfo.selectedFiller);

    const commentary = useSelector((state: RootState) => state.selectedInfo.selectedCommentary);
    const contactName = useSelector((state: RootState) => state.selectedInfo.selectedContactName);
    const receiverUUID = useSelector((state: RootState) => state.selectedInfo.selectedReceiverUID);
    const selectedNomenclature = useSelector(
        (state: RootState) => state.selectedInfo.selectedNomenclature,
    );
    const prorabName = useSelector((state: RootState) => state.selectedInfo.selectedContactName);
    const PMDValue = useSelector((state: RootState) => state.selectedInfo.PMD);
    const cars = useSelector((state: RootState) => state.selectedInfo.selectedCars);
    const clientUID = useSelector((state: RootState) => state.selectedInfo.selectedClientUID);

    const pumpsInfo = useSelector((state: RootState) => state.selectedInfo.pumps);
    const dataMarks = useSelector((state: RootState) => state.marks.dataMarks);

    if (!orderUID) {
        orderUID = '';
    }

    const navigate = useNavigate();

    const token = getCookie('token');

    async function deleteOrder() {
        if (window.confirm('Вы действительно хотите удалить заявку?')) {
            const response = await mobileProrabIntstance.post('orderDelete', {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                refreshReq(null);
                navigate('/successCancel');
            }
        }
    }

    async function cancelOrder() {
        if (window.confirm('Вы действительно хотите удалить заявку?')) {
            const response = await mobileProrabIntstance.post('orderCancel', {
                orderUID: request.order_uid,
            });

            if (response.status === 200) {
                refreshReq(null);
                navigate('/successCancel');
            }
        }
    }

    useEffect(() => {
        setIsTodaysDate(format(+date, 'dd') === format(new Date(), 'dd'));
    }, [date]);

    useEffect(() => {
        if (requestType === 'Новая') {
            if (isTodaysDate) {
                setSubmitButtonText(`${initialTextValue} на сегодня`);
            } else {
                setSubmitButtonText(`${initialTextValue}`);
            }
        }
    }, [isButtonAvaileble, isTodaysDate]);

    useEffect(() => {
        if (nomenclatureUID !== null) {
            setIsAnyFieldEmpty(false);
        }

        if (addressUID !== null) {
            setIsAnyFieldEmpty(false);
        }

        if (contactInformation !== null) {
            setIsAnyFieldEmpty(false);
        }
    }, [nomenclatureUID, addressUID, contactInformation]);

    function calculatePMD(value: string) {
        if (value === 'Без ПМД') {
            return 0;
        } else if (value === 'До -5°C') {
            return 1;
        } else if (value === 'До -10°C') {
            return 2;
        } else if (value === 'До -15°C') {
            return 3;
        } else if (value === 'До -20°C') {
            return 4;
        } else {
            return 0;
        }
    }

    function alarmEmptyFields() {
        if (pumpCount === 0) {
            setIsAnyFieldEmpty(true);

            if (refPumpSelector?.current) {
                refPumpSelector.current.style.borderBottom = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                    }`;
                refPumpSelector.current.style.borderTop = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                    }`;
                refPumpSelector.current.style.borderLeft = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                    }`;
                refPumpSelector.current.style.borderRight = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                    }`;
            }
        }

        if (nomenclatureUID === null && refNomenclatureSelector.current !== null) {
            setIsAnyFieldEmpty(true);

            refNomenclatureSelector.current.style.borderBottom = `1px solid ${
                // theme === 'dark' ? '#F54941' : '#FF8B86'
                theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refNomenclatureSelector.current.style.borderTop = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refNomenclatureSelector.current.style.borderLeft = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refNomenclatureSelector.current.style.borderRight = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;

            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (addressUID === null && refAddressSelector.current !== null) {
            setIsAnyFieldEmpty(true);

            refAddressSelector.current.style.borderBottom = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refAddressSelector.current.style.borderTop = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refAddressSelector.current.style.borderLeft = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refAddressSelector.current.style.borderRight = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;

            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        if (contactInformation === null && refPhoneSelector.current !== null) {
            setIsAnyFieldEmpty(true);

            refPhoneSelector.current.style.borderBottom = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refPhoneSelector.current.style.borderTop = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refPhoneSelector.current.style.borderLeft = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
            refPhoneSelector.current.style.borderRight = `1px solid ${theme === 'dark' ? '#FF8B86' : '#F54941'
                }`;
        }
    }

    const addMarks = () => {
        if (isButtonAvaileble) {
            setIsButtonAvaileble(false);
            dispatch(
                addMark({
                    nomenclatureUID: nomenclatureUID,
                    isRequestCopy: isRequestCopy,
                    filler: selectedFiller,
                    deliveryType: deliveryType,
                    factoryType: factoryType,
                    contactInformation: contactInformation,
                    selectedAcceptanceType: selectedAcceptanceType,
                    receivingMethod: receivingMethod,
                    contactName: contactName,
                    prorabName: prorabName,
                    commentary: commentary,
                    addressUID: addressUID,
                    intervalValue: intervalValue,
                    intensity: intensity,
                    capacity: capacity,
                    coneDraft: coneDraft,
                    date: format(date, 'yyyy-MM-dd'),
                    time: format(date, 'HH:mm'),
                    token: token,
                    receiverUID: receiverUUID,
                    orderUID: orderUID,
                    pumpCount: pumpCount,
                    laitance: laitance,
                    pmd: calculatePMD(PMDValue),
                    numberOfCars: cars,
                    counterpartUID: clientUID,
                    pumps: pumpsInfo,
                    cranes: crane,
                }),
            );

            navigate('/createRequestMarks');
        }
    };

    async function send(date: Date) {
        setSubmitButtonText('Отправка...');

        if (isButtonAvaileble) {
            setIsButtonAvaileble(false);
            if (dataMarks.length !== 0) {
                for (let i = 0; i < dataMarks.length; i++) {
                    mobileProrabIntstance
                        .post(methodToExecute, {
                            nomenclatureUID: dataMarks[i].nomenclatureUID,
                            isRequestCopy: dataMarks[i].isRequestCopy,
                            filler: dataMarks[i].filler,
                            deliveryType: dataMarks[i].deliveryType,
                            factoryType: dataMarks[i].factoryType,
                            contactInformation: contactInformation,
                            selectedAcceptanceType: dataMarks[i].selectedAcceptanceType,
                            receivingMethod: dataMarks[i].receivingMethod,
                            contactName: dataMarks[i].contactName,
                            prorabName: dataMarks[i].prorabName,
                            commentary: dataMarks[i].commentary,
                            addressUID: dataMarks[i].addressUID,
                            intervalValue: dataMarks[i].intervalValue,
                            intensity: dataMarks[i].intensity,
                            capacity: dataMarks[i].capacity,
                            coneDraft: dataMarks[i].coneDraft,
                            date: dataMarks[i].date,
                            time: dataMarks[i].time,
                            token: dataMarks[i].token,
                            receiverUID: dataMarks[i].receiverUUID,
                            orderUID: dataMarks[i].orderUID,
                            pumpCount: dataMarks[i].pumpCount,
                            laitance: dataMarks[i].laitance,
                            pmd: calculatePMD(dataMarks[i].pmd),
                            numberOfCars: dataMarks[i].numberOfCars,
                            counterpartUID: dataMarks[i].counterpartUID,
                            pumps: dataMarks[i].pumps,
                            crane: dataMarks[i].crane,
                        })
                        .then(function (response) {
                            if (response.status === 200) {
                                setSubmitButtonText('Успешно отправлено');
                                refreshReq(null);
                                navigate(successType);
                                sessionStorage.clear();
                            }
                        })
                        .catch(function (error) {
                            setSubmitButtonText('Ошибка отправки...');
                            toast.error(error);
                        });
                }
            } else {
                mobileProrabIntstance
                    .post(methodToExecute, {
                        nomenclatureUID: nomenclatureUID,
                        isRequestCopy: isRequestCopy,
                        filler: selectedFiller,
                        deliveryType: deliveryType,
                        factoryType: factoryType,
                        contactInformation: contactInformation,
                        selectedAcceptanceType: selectedAcceptanceType,
                        receivingMethod: receivingMethod,
                        contactName: contactName,
                        prorabName: prorabName,
                        commentary: commentary,
                        addressUID: addressUID,
                        intervalValue: intervalValue,
                        intensity: intensity,
                        capacity: capacity,
                        coneDraft: coneDraft,
                        date: format(date, 'yyyy-MM-dd'),
                        time: format(date, 'HH:mm'),
                        token: token,
                        receiverUID: receiverUUID,
                        orderUID: orderUID,
                        pumpCount: pumpCount,
                        laitance: laitance,
                        pmd: calculatePMD(PMDValue),
                        numberOfCars: cars,
                        counterpartUID: clientUID,
                        pumps: pumpsInfo,
                        cranes: crane,
                    })
                    .then(function (response) {
                        if (response.status === 200) {
                            setSubmitButtonText('Успешно отправлено');
                            refreshReq(null);
                            navigate(successType);
                            sessionStorage.clear();
                        }
                    })
                    .catch(function (error) {
                        setSubmitButtonText('Ошибка отправки...');
                        toast.error(error);
                    });
            }
        }
    }

    console.log(
        'address',
        addressUID,
        'nomenclature',
        nomenclatureUID,
        'type',
        selectedAcceptanceType,
        'interval',
        intervalValue,
        'intensity',
        intensity,
        'capacity',
        capacity,
        'conedraft',
        coneDraft,
        'date',
        date,
        'selectedNomenclatureType',
        selectedFiller,
    );

    if (requestType === 'marks') {
        if (
            selectedNomenclature === 'Цементное молоко' ||
            selectedNomenclature === 'Цементное молоко густое'
        ) {
            if (addressUID && nomenclatureUID && capacity && date) {
                return (
                    <div
                        style={{
                            paddingBottom: 12,
                        }}
                        className="footerSubmitButton"
                    >
                        {/* action === 'update || action === 'confirm' */}
                        {action === 'update' ? (
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                                }
                                className="delete-button"
                            >
                                <Trash theme={theme} />
                            </button>
                        ) : null}
                        <button
                            style={{
                                opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                            }}
                            disabled={localStorage.getItem('isOnlyView') === 'true'}
                            onClick={requestType === 'marks' ? () => addMarks() : () => send(date)}
                            className="submitButton active"
                        >
                            {submitButtonText}
                        </button>
                    </div>
                );
            }

            return (
                <div className="footerSubmitButton">
                    {/* action === 'update || action === 'confirm' */}
                    {action === 'update' ? (
                        <button
                            style={{
                                opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                            }}
                            disabled={localStorage.getItem('isOnlyView') === 'true'}
                            onClick={
                                action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                            }
                            className="delete-button"
                        >
                            <Trash theme={theme} />
                        </button>
                    ) : null}
                    <button disabled className="submitButton">
                        {submitButtonText}
                    </button>
                </div>
            );
        } else {
            if (
                addressUID &&
                nomenclatureUID &&
                selectedAcceptanceType &&
                intervalValue &&
                intensity &&
                capacity &&
                coneDraft &&
                date
            ) {
                return (
                    <>
                        <div className="footerSubmitButton">
                            {/* action === 'update || action === 'confirm' */}
                            {action === 'update' ? (
                                <button
                                    style={{
                                        opacity:
                                            localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                    }}
                                    disabled={localStorage.getItem('isOnlyView') === 'true'}
                                    onClick={
                                        action === 'confirm'
                                            ? () => cancelOrder()
                                            : () => deleteOrder()
                                    }
                                    className="delete-button"
                                >
                                    <Trash theme={theme} />
                                </button>
                            ) : null}
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    requestType === 'marks' ? () => addMarks() : () => send(date)
                                }
                                className="submitButton active"
                            >
                                {submitButtonText}
                            </button>
                        </div>
                        <br />
                        <br />
                    </>
                );
            }

            return (
                <>
                    {isAnyFieldEmpty && (
                        <span className="errorFieldCreate">Заполните все поля!</span>
                    )}
                    <div className="footerSubmitButton">
                        {/* action === 'update || action === 'confirm' */}
                        {action === 'update' ? (
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                                }
                                className="delete-button"
                            >
                                <Trash theme={theme} />
                            </button>
                        ) : null}
                        <button
                            style={{
                                opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                            }}
                            disabled={localStorage.getItem('isOnlyView') === 'true'}
                            onClick={() => alarmEmptyFields()}
                            className="submitButton"
                        >
                            {submitButtonText}
                        </button>
                    </div>
                    <br />
                    <br />
                    {isOpenError && (
                        <ModalError isOpen={isOpenError} onClose={() => setIsOpenError(false)} />
                    )}
                </>
            );
        }
    } else {
        if (
            selectedNomenclature === 'Цементное молоко' ||
            selectedNomenclature === 'Цементное молоко густое'
        ) {
            if (addressUID && nomenclatureUID && capacity && date && contactInformation) {
                return (
                    <div
                        style={{
                            paddingBottom: 12,
                        }}
                        className="footerSubmitButton"
                    >
                        {/* action === 'update || action === 'confirm' */}
                        {action === 'update' ? (
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                                }
                                className="delete-button"
                            >
                                <Trash theme={theme} />
                            </button>
                        ) : null}
                        {/* confirm button to call support */}

                        {action === 'confirm' ? (
                            <a
                                style={{ textDecoration: 'none' }}
                                className="submitButton active"
                                href="tel:+74996538186"
                            >
                                Связаться с поддержкой
                            </a>
                        ) : (
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    requestType === 'marks' ? () => addMarks() : () => send(date)
                                }
                                className="submitButton active"
                            >
                                {submitButtonText}
                            </button>
                        )}
                    </div>
                );
            }

            return (
                <div className="footerSubmitButton">
                    {/* action === 'update || action === 'confirm' */}
                    {action === 'update' ? (
                        <button
                            style={{
                                opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                            }}
                            disabled={localStorage.getItem('isOnlyView') === 'true'}
                            onClick={
                                action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                            }
                            className="delete-button"
                        >
                            <Trash theme={theme} />
                        </button>
                    ) : null}
                    <button disabled className="submitButton">
                        {submitButtonText}
                    </button>
                </div>
            );
        } else {
            if (
                addressUID &&
                nomenclatureUID &&
                selectedAcceptanceType &&
                intervalValue &&
                intensity &&
                capacity &&
                coneDraft &&
                contactInformation &&
                date
            ) {
                return (
                    <>
                        <div className="footerSubmitButton">
                            {/* action === 'update || action === 'confirm' */}
                            {action === 'update' ? (
                                <button
                                    style={{
                                        opacity:
                                            localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                        textDecoration: 'none',
                                    }}
                                    disabled={localStorage.getItem('isOnlyView') === 'true'}
                                    onClick={
                                        action === 'confirm'
                                            ? () => cancelOrder()
                                            : () => deleteOrder()
                                    }
                                    className="delete-button"
                                >
                                    <Trash theme={theme} />
                                </button>
                            ) : null}
                            {/* confirm button to call support */}
                            {action === 'confirm' ? (
                                <a
                                    style={{
                                        opacity:
                                            localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                        textDecoration: 'none',
                                    }}
                                    className="submitButton active"
                                    href="tel:+74996538186"
                                >
                                    Связаться с поддержкой
                                </a>
                            ) : (
                                <button
                                    style={{
                                        opacity:
                                            localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                    }}
                                    disabled={localStorage.getItem('isOnlyView') === 'true'}
                                    onClick={
                                        requestType === 'marks'
                                            ? () => addMarks()
                                            : () => send(date)
                                    }
                                    className="submitButton active"
                                >
                                    {submitButtonText}
                                </button>
                            )}
                        </div>
                        <br />
                        <br />
                    </>
                );
            }

            return (
                <>
                    {isAnyFieldEmpty && (
                        <span className="errorFieldCreate">Заполните все поля!</span>
                    )}
                    <div className="footerSubmitButton">
                        {/* action === 'update || action === 'confirm' */}
                        {action === 'update' ? (
                            <button
                                style={{
                                    opacity:
                                        localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                                }}
                                disabled={localStorage.getItem('isOnlyView') === 'true'}
                                onClick={
                                    action === 'confirm' ? () => cancelOrder() : () => deleteOrder()
                                }
                                className="delete-button"
                            >
                                <Trash theme={theme} />
                            </button>
                        ) : null}
                        <button
                            style={{
                                opacity: localStorage.getItem('isOnlyView') === 'true' ? 0.5 : 1,
                            }}
                            disabled={localStorage.getItem('isOnlyView') === 'true'}
                            onClick={() => alarmEmptyFields()}
                            className="submitButton"
                        >
                            {submitButtonText}
                        </button>
                    </div>
                    <br />
                    <br />
                    {isOpenError && (
                        <ModalError isOpen={isOpenError} onClose={() => setIsOpenError(false)} />
                    )}
                </>
            );
        }
    }
}

export default SubmitButton;
